import ApiService, { HTTPMethod } from './api.core'
import { Transformer } from '../models/Transformer'
import { isToday, getAiWithoutId, removeDuplicateAdditionalInsured } from '../services/utils'

class InsuranceApi {
  async getActiveInsurances() {
    let res = await ApiService.fetch('insurances/me/active')
    if (res.ok && res.data && res.data.length > 0) {
      let activeInsurance = res.data.filter(x => x.policy_status == 'Active')
      if (!activeInsurance.length) {
        activeInsurance = res.data
      }
      let activeInsuranceTransformed = Transformer.parseActiveInsuranceModel(activeInsurance[0])
      res.parsedData = activeInsuranceTransformed
    }
    return res
  }

  async purchaseInsurance(optionId, additionalInsured, autoRenew, externalActorId, paymentMethod) {
    let body = {}
    body.offer_id = optionId
    body.auto_renew = !!autoRenew
    body.payment_method = paymentMethod

    if (externalActorId) {
      body.external_actor_id = externalActorId
    }

    if (additionalInsured) {
      body.additional_insureds = removeDuplicateAdditionalInsured(additionalInsured.map(q => getAiWithoutId(q)))
    }

    let res = await ApiService.fetch('insurances/', HTTPMethod.POST, body)

    if (res.ok) {
      let activeInsurance = Transformer.parseActiveInsuranceModel(res.data)
      res.parsedData = activeInsurance
    }
    return res
  }

  async createCancellationPdf(insuranceId) {
    let res = await ApiService.fetch(`insurances/${insuranceId}/CreateCancelPdf`, HTTPMethod.POST)
    return res
  }

  async sendAQuote(insurancePurchaseContext, offersContext, emails, companyName) {
    let body = {
      liability: insurancePurchaseContext.selectedOption.liabilityLimit,
      auto_renew: !!insurancePurchaseContext.autoRenew,
      additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
      emails: emails,
      name: companyName,
      drones: insurancePurchaseContext.drones || [],
      equipment: insurancePurchaseContext.equipment || [],
      billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
      coverage_type: offersContext.coverageType,
      operators: offersContext.operators,
      maximum_uas_in_air: offersContext.maximumUasInAir,
      territorial_limits: offersContext.worldWideCoverage.territorialLimits,
      territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
      personal_injury_limit: offersContext.personalInjuryCoverage,
      medical_expense_limit: offersContext.medicalExpense,
      custom_premium: offersContext.specialPrice.custom_premium,
      custom_premium_token: offersContext.specialPrice.custom_premium_token,
      indoor_coverage: offersContext.indoorCoverage.included,
      indoor_coverage_description: offersContext.indoorCoverage.description,
    }
    if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
      body['start_time'] = offersContext.subscriptionStartTime.getTime()
    }
    return await ApiService.fetch('insurances/quote', HTTPMethod.POST, body)
  }

  async saveQuoteState(insurancePurchaseContext, offersContext) {
    let body = {
      liability: insurancePurchaseContext.selectedOption.liabilityLimit,
      auto_renew: !!insurancePurchaseContext.autoRenew,
      additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
      drones: insurancePurchaseContext.drones || [],
      equipment: insurancePurchaseContext.equipment || [],
      billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
      coverage_type: offersContext.coverageType,
      operators: offersContext.operators,
      maximum_uas_in_air: offersContext.maximumUasInAir,
      territorial_limits: offersContext.worldWideCoverage.territorialLimits,
      territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
      personal_injury_limit: offersContext.personalInjuryCoverage,
      medical_expense_limit: offersContext.medicalExpense,
      custom_premium: offersContext.specialPrice.custom_premium,
      custom_premium_token: offersContext.specialPrice.custom_premium_token,
      indoor_coverage: offersContext.indoorCoverage.included,
      indoor_coverage_description: offersContext.indoorCoverage.description,
    }
    if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
      body['start_time'] = offersContext.subscriptionStartTime.getTime()
    }
    return await ApiService.fetch('insurances/purchaseState', HTTPMethod.POST, body)
  }

  async referQuote(insurancePurchaseContext, offersContext) {
    let body = {
      liability: insurancePurchaseContext.selectedOption.liabilityLimit,
      auto_renew: !!insurancePurchaseContext.autoRenew,
      additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
      drones: insurancePurchaseContext.drones || [],
      equipment: insurancePurchaseContext.equipment || [],
      billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
      coverage_type: offersContext.coverageType,
      operators: offersContext.operators,
      maximum_uas_in_air: offersContext.maximumUasInAir,
      territorial_limits: offersContext.worldWideCoverage.territorialLimits,
      territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
      personal_injury_limit: offersContext.personalInjuryCoverage,
      medical_expense_limit: offersContext.medicalExpense,
      custom_premium: offersContext.specialPrice.custom_premium,
      custom_premium_token: offersContext.specialPrice.custom_premium_token,
      indoor_coverage: offersContext.indoorCoverage.included,
      indoor_coverage_description: offersContext.indoorCoverage.description,
    }
    if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
      body['start_time'] = offersContext.subscriptionStartTime.getTime()
    }
    return await ApiService.fetch('insurances/refer-quote', HTTPMethod.POST, body)
  }

  async updateEstimatedMiles(insuranceId, vehicleId, estimated_mileage_value) {
    let body = {
      insurance_id: insuranceId,
      vehicle_id: vehicleId,
      estimated_miles_value: estimated_mileage_value,
    }
    return await ApiService.fetch(`insurances/update_estimated_miles`, HTTPMethod.POST, body)
  }

  async getOdometerUploadLink(insuranceId) {
    return await ApiService.fetch(`insurances/${insuranceId}/odometer/odometer_upload_link`, HTTPMethod.POST)
  }

  async getEstimatedMilesDetails(userId) {
    let result = await ApiService.fetch('insurances/get_estimated_miles_details')
    return result.data
  }
}

export default new InsuranceApi()
